import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {
    private alertStore = inject(AlertStore);

    private openAlert = (message: string): void => {
        this.alertStore.openAlert({
            title: 'Ocorreu um erro',
            type: 'danger',
            message: message
        });
    }

    private getErrorMessage = (error: HttpErrorResponse): string | null => {

        if (error?.error?.errors) {
            const errorKeys = Object.keys(error.error.errors);
            return error.error.errors[errorKeys[0]][0];
        }

        if (error?.error?.Validacao?.Erros) {
            return error.error.Validacao.Erros;
        }

        if (error?.error?.ValidationResult?.Errors) {
            return error.error.ValidationResult.Errors.map((element: any) => element.ErrorMessage)[0];
        }

        if (error?.error?.ModelState?.Erros) {
            return error.error.ModelState.Erros;
        }

        if (error?.error?.errors?.DomainValidations) {
            return error.error.errors.DomainValidations;
        }

        if (error?.error?.Erros) {
            return error.error.Erros;
        }

        return null;
    }

    public handleError(error: HttpErrorResponse): void {
        const errorMessages: string | null = this.getErrorMessage(error);

        if (errorMessages) {
            this.openAlert(errorMessages);
            return;
        }

        if (error.status === 400) {
            this.openAlert('Ocorreu um erro de requisição');
        }

        if (error.status === 404) {
            this.openAlert('O recurso não foi encontrado');
        }

        if (error.status === 401) {
            this.openAlert('Você não tem permissão para acessar este recurso');
        }

        if (error.status === 403) {
            this.openAlert('Você não tem permissão para acessar este recurso');
        }

        if (error.status === 500) {
            this.openAlert('Ocorreu um erro interno no servidor');
        }
    }

}