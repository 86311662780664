export enum EAlertTypes {
  INFO = 'info',
  DANGER = 'danger',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum EAlertModes {
  ALERT = 'alert',
  CONFIRM = 'confirm',
}
