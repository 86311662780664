import { CommonModule } from '@angular/common';
import { Component, ElementRef, Signal, effect, inject, input, untracked, viewChild } from '@angular/core';
import { IAlertItem } from '@app-models/entities/alerts/alerts.interface';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  imports: [CommonModule],
  standalone: true
})
export class AlertComponent {
  public readonly alertStore = inject(AlertStore);
  public readonly bar: Signal<ElementRef<HTMLSpanElement> | undefined> = viewChild('bar');
  public readonly alertBox: Signal<ElementRef<HTMLDivElement> | undefined> = viewChild('alertBox');
  public alert = input<Partial<IAlertItem>>();

  private observeAlert = effect(() => {
    const barComponent = this.bar()?.nativeElement;
    const alertBoxComponent = this.alertBox()?.nativeElement;
    untracked(() => {
      if (barComponent && alertBoxComponent) {
        const height = alertBoxComponent.getBoundingClientRect().height;
        alertBoxComponent.animate([
          { height: '0px' },
          { height: `${height}px` },
        ], { duration: 200, easing: 'ease', fill: 'forwards' }).onfinish = () => {
          barComponent.animate([
            { width: '100%' },
            { width: '0px' },
          ], { duration: 5000, easing: 'ease-in-out', fill: 'forwards' }).onfinish = () => {
            alertBoxComponent.animate([
              { height: `${height}px` },
              { height: '0px' },
            ], { duration: 200, easing: 'ease', fill: 'forwards' }).onfinish = () => {
              this.alertStore.closeAlert(String(this.alert()?.id));
            }
          }
        }
      }
    })

  })
}
