import { Component } from '@angular/core';
import { ChildrenOutletContexts, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AlertListComponent } from '@shared/components/alert/feature/alert-list.component';
import { slideInAnimation } from 'src/shared/animations/animations';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, RouterModule, AlertListComponent],
  animations: [slideInAnimation],
})
export class AppComponent {

  constructor(private contexts: ChildrenOutletContexts) { }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
}
