import { Component, inject } from "@angular/core";
import { LogoutStore } from "@app-logout/data-access/logout.store";
import { AuthStore } from "@app-auth/data-access/auth.store";
import { ProfileStore } from "@app-profile/data-access/profile.store";
import { SlideUpComponent } from "@shared/components/slide-up/slide-up.component";
import { ButtonComponent } from "@shared/components/button/button.component";

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    standalone: true,
    imports: [SlideUpComponent, ButtonComponent],
}) export class LogoutComponent {
    public readonly logoutStore = inject(LogoutStore);
    public readonly authStore = inject(AuthStore);
    public readonly profileStore = inject(ProfileStore);

    public logout(): void {
        this.logoutStore.toggleLogoutDialog(false);
        this.profileStore.clearStore();
        this.authStore.logout();
    }
}