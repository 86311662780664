import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { WorkoutService } from '@app-training/data-access/infra/workout.service';
import { catchError, concatMap, iif, of, pipe, switchMap, tap } from 'rxjs';
import { LocalStorageService } from '@app-services/localStorage.service';
import { DateTime, WeekdayNumbers } from 'luxon';
import { IExercise, ISeriesLoadProgression, ITimeValues, IUpdateLoad, IWorkout, IWorkoutItem, IWorkoutPlan, IWorkoutWeekLog } from '@app-training/data-access/entities/workouts.interface';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';
import { ErrorHandlerService } from '@app-services/error-handler.service';
import { setPropError, setPropInit, setPropLoaded, setPropLoading, withReqState } from '@shared/stores/prop-state.store';
import Timer, { TimeCounter } from 'easytimer.js';
import { Router } from '@angular/router';
import { EAlertTypes } from '@shared/components/alert/data-access/entities/alert.enum';

interface IWorkoutState {
    ActiveWorkoutPlan: IWorkoutPlan | null;
    SelectedWorkout: IWorkout | null;
    WorkoutOfTheDay: IWorkout | null;
    Exercises: Record<string, IExercise>;
    RestartSeriesStates: Record<string, 'loaded' | 'loading' | 'error'>;
    FinishSeriesStates: Record<string, 'loaded' | 'loading' | 'error'>;
    IncrementSeriesStates: Record<string, 'loaded' | 'loading' | 'error'>;
    DecrementSeriesStates: Record<string, 'loaded' | 'loading' | 'error'>;
    WorkoutTimer: Record<string, Timer>;
    WorkoutWeekLog: IWorkoutWeekLog[] | null;
};

const initialState: IWorkoutState = {
    ActiveWorkoutPlan: null,
    SelectedWorkout: null,
    WorkoutOfTheDay: null,
    Exercises: {},
    RestartSeriesStates: {},
    FinishSeriesStates: {},
    IncrementSeriesStates: {},
    DecrementSeriesStates: {},
    WorkoutTimer: {},
    WorkoutWeekLog: null
};

export const WorkoutStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        workoutService = inject(WorkoutService),
        localStorageService = inject(LocalStorageService),
        alertStore = inject(AlertStore),
        errorHandlerService = inject(ErrorHandlerService),
        router = inject(Router),
    ) => {
        const updateSetItems = (workout: IWorkout) => {
            const setItemsBySet: Record<number, IWorkoutItem[]> = {};
            workout.TrainingItems.forEach(workoutItem => {
                if (!workoutItem.SeriesLoadsProgressions?.length) {
                    workoutItem.SeriesLoadsProgressions = [{
                        Repetition: '',
                        Load: '',
                        SeriesLoadProgressionId: '',
                        Time: '',
                        Velocity: '',
                        CurrentSerie: 1,
                        TrainingItemId: workoutItem.TrainingItemId
                    }]
                }
                if (workoutItem.Set) {
                    if (setItemsBySet[workoutItem.Set]) {
                        const firstItem = setItemsBySet[workoutItem.Set][0];
                        if (workoutItem.SeriesLoadsProgressions?.length !== firstItem.SeriesLoadsProgressions?.length) {
                            workoutItem.SeriesLoadsProgressions = firstItem.SeriesLoadsProgressions?.map((serieFirstItem, index) => {
                                const serie = workoutItem.SeriesLoadsProgressions?.[index];
                                if (serie) return serie;
                                const newSerie: ISeriesLoadProgression = {
                                    Repetition: '',
                                    Load: '',
                                    SeriesLoadProgressionId: '',
                                    Time: '',
                                    Velocity: '',
                                    CurrentSerie: serieFirstItem.CurrentSerie,
                                    TrainingItemId: serieFirstItem.TrainingItemId
                                }
                                return newSerie;
                            });
                        }
                        setItemsBySet[workoutItem.Set].push(workoutItem)
                    } else {
                        setItemsBySet[workoutItem.Set] = [workoutItem];
                    }
                }
            });
            workout.SetItems = setItemsBySet;
            return workout;
        };

        const alreadyTrainedToday = (): boolean => {
            return !!store.ActiveWorkoutPlan()?.Trainings.find(workout => {
                const today = DateTime.now().endOf('day');

                const lastPerfomedOn = DateTime.fromISO(workout.LastPerfomedOn).endOf('day');
                const diffLastPerfomedOn = today.diff(lastPerfomedOn, 'days').days;

                const LastPrintedOn = DateTime.fromISO(workout.LastPrintedOn).endOf('day');
                const diffLastPrintedOn = today.diff(LastPrintedOn, 'days').days;

                return diffLastPrintedOn === 0 || diffLastPerfomedOn === 0;
            });
        }

        const reordingWorkouts = () => {
            const ActiveWorkoutPlan = store.ActiveWorkoutPlan();
            if (!ActiveWorkoutPlan) return;
            ActiveWorkoutPlan.Trainings = ActiveWorkoutPlan.Trainings.sort((a, b) => {
                if (!a.LastPerfomedOn && !a.LastPrintedOn) return -1;

                const aLastPerfomedOn = DateTime.fromISO(a.LastPerfomedOn);
                const bLastPerfomedOn = DateTime.fromISO(b.LastPerfomedOn);

                const aLastPrintedOn = DateTime.fromISO(a.LastPrintedOn);
                const bLastPrintedOn = DateTime.fromISO(b.LastPrintedOn);

                const aDateTime = aLastPerfomedOn > aLastPrintedOn ? aLastPerfomedOn : aLastPrintedOn;
                const bDateTime = bLastPerfomedOn > bLastPrintedOn ? bLastPerfomedOn : bLastPrintedOn;

                return aDateTime < bDateTime ? -1 : 1;
            });
            patchState(store, { ActiveWorkoutPlan, ...setPropLoaded('ActiveWorkoutPlan') });
        }

        const startWorkout = rxMethod<string>(pipe(
            tap(() => patchState(store, { ...setPropLoading('startWorkout') })),
            switchMap((trainingId) => {
                return workoutService.startWorkout(trainingId).pipe(
                    tap(() => {
                        patchState(store, { ...setPropLoaded('startWorkout') });
                    }),
                    catchError((error) => {
                        patchState(store, { ...setPropError('startWorkout') });
                        errorHandlerService.handleError(error);
                        return of(error);
                    })
                )
            })
        ))

        const removeTimeById = (id: string) => {
            const localTimeValuesStr = localStorageService.getItem('workout-time-values');
            const localTimeValuesObj = localTimeValuesStr ? JSON.parse(localTimeValuesStr) : {};
            delete localTimeValuesObj[id];
            localStorageService.setItem('workout-time-values', JSON.stringify(localTimeValuesObj));
        }

        const stopWorkoutTimer = (id: string) => {
            store.WorkoutTimer()[id]?.stop();
            const localTimeValuesStr = localStorageService.getItem('workout-time-values');
            const localTimeValuesObj = localTimeValuesStr ? JSON.parse(localTimeValuesStr) : {};
            delete localTimeValuesObj[id];
            localStorageService.setItem('workout-time-values', JSON.stringify(localTimeValuesObj));
        }

        return {
            getWorkoutPlan: rxMethod<void>(pipe(
                switchMap(() => {
                    const authorization = JSON.parse(localStorageService!.getItem('authorization')!);
                    const personId = authorization.PersonId;
                    return workoutService.getWorkoutPlanActiveByPersonId(personId).pipe(
                        tap((ActiveWorkoutPlan) => {
                            ActiveWorkoutPlan.Trainings = ActiveWorkoutPlan.Trainings.map(training => {
                                training = updateSetItems(training);

                                if (!training.isWorkoutStarted) {
                                    const localTimeValuesStr = localStorageService.getItem('workout-time-values');
                                    const localTimeValuesObj = localTimeValuesStr ? JSON.parse(localTimeValuesStr) : {};
                                    const workoutTime = localTimeValuesObj[training.TrainingId] as ITimeValues;

                                    const exerciseHasMinDuration = workoutTime?.timeValues?.minutes >= 2;
                                    const itemsPerformeds = training.TrainingItems.filter(item => item.SeriesPerformeds >= 1);

                                    training.isWorkoutStarted = (exerciseHasMinDuration && itemsPerformeds.length >= 1) || itemsPerformeds.length >= 2;

                                    if (!training.isWorkoutStarted) {
                                        removeTimeById(training.TrainingId);
                                    }
                                }
                                return training;
                            });
                            patchState(store, { ActiveWorkoutPlan, ...setPropLoaded('ActiveWorkoutPlan') });
                            reordingWorkouts();

                            if (ActiveWorkoutPlan) {
                                const WorkoutOfTheDay = alreadyTrainedToday() ? null : ActiveWorkoutPlan.Trainings.find(training => training.TrainingId === ActiveWorkoutPlan.WorkoutOfTheDayId);
                                patchState(store, { WorkoutOfTheDay });
                            }
                        }),
                        catchError((error) => {
                            patchState(store, { ActiveWorkoutPlan: null, ...setPropError('ActiveWorkoutPlan') });
                            errorHandlerService.handleError(error);
                            return of(error);
                        })
                    )
                }
                )
            )),

            selectWorkout: (id: string) => {
                const workout = store.ActiveWorkoutPlan()?.Trainings.find(training => training.TrainingId === id);
                patchState(store, { SelectedWorkout: workout });
                return workout;
            },

            incrementSeries: rxMethod<string>(pipe(
                tap((trainingItemId) => patchState(store, { IncrementSeriesStates: { ...store.IncrementSeriesStates(), [trainingItemId]: 'loading' } })),
                concatMap((trainingItemId) => {
                    return workoutService.incrementSeries(trainingItemId).pipe(
                        tap((response) => {
                            const workout = store.SelectedWorkout();
                            if (workout) {
                                const workoutItem = workout.TrainingItems.find(item => item.TrainingItemId === trainingItemId);
                                if (workoutItem) {
                                    workoutItem.SeriesPerformeds = response.SeriesPerformeds;
                                    patchState(store, { SelectedWorkout: workout, IncrementSeriesStates: { ...store.IncrementSeriesStates(), [trainingItemId]: 'loaded' } });
                                }

                                if (!workout.isWorkoutStarted) {
                                    const exerciseHasMinDuration = store.WorkoutTimer()[workout.TrainingId].getTimeValues().minutes >= 2;
                                    const itemsPerformeds = workout.TrainingItems.filter(item => item.SeriesPerformeds >= 1);

                                    if (itemsPerformeds && ((exerciseHasMinDuration && itemsPerformeds.length >= 1) || itemsPerformeds.length >= 2)) {
                                        startWorkout(workout.TrainingId);
                                        workout.isWorkoutStarted = true;
                                    }
                                }
                            }
                        }),
                        catchError((error) => {
                            patchState(store, { IncrementSeriesStates: { ...store.IncrementSeriesStates(), [trainingItemId]: 'error' } });
                            errorHandlerService.handleError(error);
                            return of(error);
                        })
                    )
                }),
            )),

            decrementSeries: rxMethod<string>(pipe(
                tap((trainingItemId) => patchState(store, { DecrementSeriesStates: { ...store.DecrementSeriesStates(), [trainingItemId]: 'loading' } })),
                concatMap((trainingItemId) => {
                    return workoutService.decrementSeries(trainingItemId).pipe(
                        tap((response) => {
                            const workout = store.SelectedWorkout();
                            if (workout) {
                                const workoutItem = workout.TrainingItems.find(item => item.TrainingItemId === trainingItemId);
                                if (workoutItem) {
                                    workoutItem.SeriesPerformeds = response.SeriesPerformeds;
                                    patchState(store, { SelectedWorkout: workout, DecrementSeriesStates: { ...store.DecrementSeriesStates(), [trainingItemId]: 'loaded' } });
                                }
                            }
                        }),
                        catchError((error) => {
                            patchState(store, { DecrementSeriesStates: { ...store.DecrementSeriesStates(), [trainingItemId]: 'error' } });
                            errorHandlerService.handleError(error);
                            return of(error);
                        })
                    )
                })
            )),

            restartSeries: rxMethod<string>(pipe(
                tap((trainingItemId) => patchState(store, { RestartSeriesStates: { ...store.RestartSeriesStates(), [trainingItemId]: 'loading' } })),
                concatMap((trainingItemId) => {
                    return workoutService.restartSeries(trainingItemId).pipe(
                        tap(() => {
                            const workout = store.SelectedWorkout();
                            if (workout) {
                                const workoutItem = workout.TrainingItems.find(item => item.TrainingItemId === trainingItemId);
                                if (workoutItem) {
                                    workoutItem.SeriesPerformeds = 0;
                                    patchState(store, { SelectedWorkout: workout, RestartSeriesStates: { ...store.RestartSeriesStates(), [trainingItemId]: 'loaded' } });
                                }
                            }
                        }),
                        catchError((error) => {
                            patchState(store, { RestartSeriesStates: { ...store.RestartSeriesStates(), [trainingItemId]: 'error' } });
                            errorHandlerService.handleError(error);
                            return of(error);
                        })
                    )
                })
            )),

            finishSeries: rxMethod<string>(pipe(
                tap((trainingItemId) => patchState(store, { FinishSeriesStates: { ...store.FinishSeriesStates(), [trainingItemId]: 'loading' } })),
                concatMap((trainingItemId) => {
                    return workoutService.finishSeries(trainingItemId).pipe(
                        tap(() => {
                            const workout = store.SelectedWorkout();
                            if (workout) {
                                const workoutItem = workout.TrainingItems.find(item => item.TrainingItemId === trainingItemId);
                                if (workoutItem) {
                                    workoutItem.SeriesPerformeds = workoutItem.SeriesLoadsProgressions.length;
                                    patchState(store, { SelectedWorkout: workout, FinishSeriesStates: { ...store.FinishSeriesStates(), [trainingItemId]: 'loaded' } });
                                }

                                if (!workout.isWorkoutStarted) {
                                    const exerciseHasMinDuration = store.WorkoutTimer()[workout.TrainingId].getTimeValues().minutes >= 2;
                                    const itemsPerformeds = workout.TrainingItems.filter(item => item.SeriesPerformeds >= 1);

                                    if (itemsPerformeds && ((exerciseHasMinDuration && itemsPerformeds.length >= 1) || itemsPerformeds.length >= 2)) {
                                        startWorkout(workout.TrainingId);
                                        workout.isWorkoutStarted = true;
                                    }
                                }
                            }
                        }),
                        catchError((error) => {
                            patchState(store, { FinishSeriesStates: { ...store.FinishSeriesStates(), [trainingItemId]: 'error' } });
                            errorHandlerService.handleError(error);
                            return of(error);
                        })
                    )
                })
            )),

            getExerciseById: rxMethod<string>(pipe(
                switchMap((exerciseId) => iif(
                    () => !store.Exercises()[exerciseId],
                    workoutService.getExerciseById(exerciseId).pipe(
                        tap((exercise) => {
                            patchState(store, { Exercises: { ...store.Exercises(), [exerciseId]: exercise } });
                        }),
                        catchError((error) => {
                            patchState(store, { ActiveWorkoutPlan: null, ...setPropError('ActiveWorkoutPlan') });
                            errorHandlerService.handleError(error);
                            return of(error);
                        })
                    ),
                    of(null)
                ))
            )),

            updateLoad: rxMethod<IUpdateLoad[]>(pipe(
                tap(() => patchState(store, { ...setPropLoading('UpdateCharge') })),
                switchMap((payload) => {
                    return workoutService.updateLoad(payload).pipe(
                        tap(() => {
                            const workout = store.SelectedWorkout();

                            if (workout) {
                                const workoutItem = workout.TrainingItems.find(item => item.TrainingItemId === payload[0].TrainingItemId);
                                if (workoutItem) {
                                    workoutItem.SeriesLoadsProgressions = workoutItem.SeriesLoadsProgressions?.map(serie => {
                                        const correspondingPayloadItem = payload.find(item => item.SeriesLoadProgressionId === serie.SeriesLoadProgressionId);
                                        if (correspondingPayloadItem) {
                                            serie.Load = correspondingPayloadItem.Load;
                                        }
                                        return serie;
                                    });

                                    patchState(store, { SelectedWorkout: workout, ...setPropLoaded('UpdateCharge') });
                                }
                            }
                        }),
                        catchError((error) => {
                            patchState(store, { ...setPropError('finishWorkout') });
                            errorHandlerService.handleError(error);
                            return of(error);
                        })
                    )
                })
            )),

            startWorkoutTimer: (key: string) => {
                const timer = store.WorkoutTimer()[key] ?? new Timer();
                const localTimeValuesStr = localStorageService.getItem('workout-time-values');
                const localTimeValuesObj = localTimeValuesStr ? JSON.parse(localTimeValuesStr) : {};
                timer.removeAllEventListeners();

                timer.on('minutesUpdated', () => {
                    const workout = store.SelectedWorkout();
                    if (workout && !workout.isWorkoutStarted) {
                        const exerciseHasMinDuration = timer.getTimeValues().minutes >= 2;
                        const itemsPerformeds = workout.TrainingItems.filter(item => item.SeriesPerformeds >= 1);

                        if (itemsPerformeds && ((exerciseHasMinDuration && itemsPerformeds.length >= 1) || itemsPerformeds.length >= 2)) {
                            startWorkout(workout.TrainingId);
                            workout.isWorkoutStarted = true;
                        }
                    }
                });

                timer.on('secondsUpdated', () => {
                    localTimeValuesObj[key] = { timeValues: timer.getTimeValues(), updateOn: DateTime.now().toISO() };
                    localStorageService.setItem('workout-time-values', JSON.stringify(localTimeValuesObj));
                });

                timer.start({ startValues: localTimeValuesObj[key]?.timeValues || {} });

                patchState(store, { WorkoutTimer: { ...store.WorkoutTimer, [key]: timer } });
            },

            pauseWorkoutTimer: (id: string) => {
                store.WorkoutTimer()[id]?.pause();
            },

            stopWorkoutTimer,

            removeOldTimeValues: () => {
                const localTimeValuesStr = localStorageService.getItem('workout-time-values');
                const localTimeValuesObj = localTimeValuesStr ? JSON.parse(localTimeValuesStr) : {};
                const newLocalTimeValuesObj = Object.entries<{ timeValues: TimeCounter, updateOn: string }>(localTimeValuesObj).reduce((acc, [key, value]) => {
                    if (DateTime.fromISO(value.updateOn).plus({ hours: 24 }) < DateTime.now()) {
                        return acc;
                    }
                    return { ...acc, [key]: value };
                }, {});
                localStorageService.setItem('workout-time-values', JSON.stringify(newLocalTimeValuesObj));
            },

            loadingActiveWorkoutPlan: () => {
                patchState(store, { ...setPropLoading('ActiveWorkoutPlan') })
            },

            finishWorkout: rxMethod<string>(pipe(
                tap(() => patchState(store, { ...setPropLoading('finishWorkout') })),
                switchMap((trainingId) => {
                    return workoutService.finishWorkout(trainingId).pipe(
                        tap((response) => {
                            alertStore.openAlert({
                                title: 'Treino concluído!',
                                mode: 'alert',
                                message: `O treino de ${store.SelectedWorkout()?.Name || `Treino ${store.SelectedWorkout()?.Flag}`} acaba de ser concluído. Continue para o próximo treino.`,
                                type: EAlertTypes.SUCCESS
                            });
                            const ActiveWorkoutPlan = store.ActiveWorkoutPlan();
                            ActiveWorkoutPlan?.Trainings.map(training => {
                                if (training.TrainingId === trainingId) {
                                    training.LastPerfomedOn = DateTime.now().toISO();
                                    training.TrainingItems = training.TrainingItems.map(item => ({ ...item, SeriesPerformeds: 0 }));
                                    training.isWorkoutStarted = false;
                                }
                                return training;
                            })

                            if (ActiveWorkoutPlan && response?.SessionsWorkoutPlanPerformed) {
                                ActiveWorkoutPlan!.SessionsWorkoutPlanPerformed = response.SessionsWorkoutPlanPerformed;
                            }

                            patchState(store, { ActiveWorkoutPlan });
                            reordingWorkouts();
                            patchState(store, { WorkoutOfTheDay: null, ...setPropLoaded('finishWorkout') });
                            router.navigate(['/training']);
                            stopWorkoutTimer(trainingId);
                        }),
                        catchError((error) => {
                            patchState(store, { ...setPropError('finishWorkout') });
                            errorHandlerService.handleError(error);
                            return of(error);
                        })
                    )
                })
            )),

            clearSerieState: (id: string) => {
                const IncrementSeriesStates = store.IncrementSeriesStates();
                const DecrementSeriesStates = store.DecrementSeriesStates();
                const RestartSeriesStates = store.RestartSeriesStates();
                const FinishSeriesStates = store.FinishSeriesStates();
                delete IncrementSeriesStates[id];
                delete DecrementSeriesStates[id];
                delete RestartSeriesStates[id];
                delete FinishSeriesStates[id];

                patchState(store, { IncrementSeriesStates, DecrementSeriesStates, RestartSeriesStates, FinishSeriesStates });
            },

            clearActiveWorkoutPlan: () => {
                patchState(store, { ActiveWorkoutPlan: null }, setPropInit('ActiveWorkoutPlan'));
            },

            blankState: () => {
                patchState(store, initialState, setPropInit('ActiveWorkoutPlan', 'UpdateCharge', 'finishWorkout', 'startWorkout', 'WorkoutWeekLog'));
            },

            getWeekLogByPersonId: rxMethod<string>(pipe(
                tap(() => patchState(store, { ...setPropLoading('WorkoutWeekLog') })),
                switchMap((personId) => {
                    return workoutService.getWeekLogByPersonId(personId).pipe(
                        tap((response) => {
                            const WorkoutWeekLog = response.map((log) => {
                                const firstDateOfWeek = DateTime.now().startOf('week');
                                const date = firstDateOfWeek.set({ day: log.Day }).plus({ month: log.Day < firstDateOfWeek.day ? 1 : 0 });

                                return { ...log, DayOfWeek: date.toFormat('EEEEE', { locale: 'pt-BR' }) }
                            })
                            patchState(store, { WorkoutWeekLog, ...setPropLoaded('WorkoutWeekLog') });
                        }),
                        catchError((error) => {
                            patchState(store, { ...setPropError('WorkoutWeekLog') });
                            errorHandlerService.handleError(error);
                            return of(error);
                        })
                    )
                })
            ))
        };
    }),
    withReqState('ActiveWorkoutPlan', 'UpdateCharge', 'finishWorkout', 'startWorkout', 'WorkoutWeekLog'),
);