import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

interface ILogoutState {
    ShowDialog: boolean;
};

const initialState: ILogoutState = {
    ShowDialog: false
};

export const LogoutStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
    ) => {
        return {
            toggleLogoutDialog(show: boolean): void {
                patchState(store, { ShowDialog: show });
            }
        };
    }),
);