import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap, tap } from 'rxjs';
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import * as cryptoJs from 'crypto-js';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';
import { ErrorHandlerService } from '@app-services/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@app-auth/data-access/infra/auth.service';
import { IAccountStatus, IAuthAccessToken, IPersonByUser, IAuthPayload, IClientData } from '@app-auth/data-access/entities/auth.interface';
import { LocalStorageService } from '@app-services/localStorage.service';
import { Router } from '@angular/router';
import { setPropError, setPropInit, setPropLoaded, setPropLoading, withReqState } from '@shared/stores/prop-state.store';
import { LogoutStore } from '@app-logout/data-access/logout.store';

interface IAuthState {
    AuthData: IAuthAccessToken | null;
    PeopleByUser: IPersonByUser[] | null;
    ClientData: IClientData | null;
    AccountStatus: IAccountStatus | null;
};

const initialState: IAuthState = {
    AuthData: null,
    PeopleByUser: null,
    ClientData: null,
    AccountStatus: null
};

export const AuthStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        authService = inject(AuthService),
        alertStore = inject(AlertStore),
        errorHandlerService = inject(ErrorHandlerService),
        localStorageService = inject(LocalStorageService),
        router = inject(Router),
        logoutStore = inject(LogoutStore)
    ) => {

        const setAuthData = (authData: IAuthAccessToken): void => {
            localStorageService.setItem('authorization', JSON.stringify(authData));
            patchState(store, { AuthData: authData });
        }

        const getAllPersonsByUser = rxMethod<void>(pipe(
            switchMap(() => authService.getAllPersonsByUser().pipe(
                tapResponse({
                    next: (PeopleByUser) => {
                        patchState(store, { PeopleByUser, ...setPropLoaded('AuthData') });
                        if (PeopleByUser.length > 1) {
                            router.navigate(['/common/accounts']);
                            return;
                        }
                        if (PeopleByUser.length === 0 || PeopleByUser.length === 1) {
                            router.navigate(['/home']);
                        }
                    },
                    error: (error: HttpErrorResponse) => {
                        patchState(store, { ...setPropError('AuthData', error) });
                    }
                })
            ))
        ))

        const initState = (): void => {
            patchState(store, {
                ...initialState,
                ...setPropInit(
                    'sendEmailForgotPassword',
                    'checkResetPasswordCode',
                    'resetPassword',
                    'checkAccountStatus',
                    'AuthData',
                    'confirmEmailAccountAndCreatePassword'
                ),
            });
        }

        const logout = (): void => {
            initState();
            logoutStore.toggleLogoutDialog(false);
            localStorageService.clearAllData();
            router.navigate(['/common/login']);
        }

        return {
            logout,

            login: rxMethod<Omit<IAuthPayload, 'AppSource' | 'Code'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('AuthData') })),
                switchMap((payload) => {
                    payload.Email = payload.Email.toLowerCase();
                    payload.Password = String(cryptoJs.MD5(payload.Email + payload.Password));

                    return authService.login({ ...payload, AppSource: 'treino' }).pipe(
                        tapResponse({
                            next: (authData) => {
                                setAuthData(authData);
                                getAllPersonsByUser();
                            },
                            error: (error: HttpErrorResponse) => {
                                patchState(store, { ...setPropError('AuthData', error) });
                            }
                        })
                    );
                })
            )),

            choosePersonAccount: rxMethod<string>(pipe(
                tap(() => patchState(store, { ...setPropLoading('AuthData') })),
                switchMap((personId) => authService.choosePersonAccount(personId).pipe(
                    tapResponse({
                        next: (result) => {
                            patchState(store, { ...setPropLoaded('AuthData') });
                            setAuthData(result);
                            router.navigate(['/home']);
                        },
                        error: (error: HttpErrorResponse) => {
                            patchState(store, { ...setPropError('AuthData', error) });
                            errorHandlerService.handleError(error);
                        }
                    })
                ))
            )),

            getClient: rxMethod<string>(pipe(
                switchMap((personId) => authService.getClient(personId).pipe(
                    tapResponse({
                        next: (response) => {
                            patchState(store, { ClientData: response });
                        },
                        error: (error: HttpErrorResponse) => {
                            errorHandlerService.handleError(error);
                        }
                    })
                ))
            )),


            checkAccountStatus: rxMethod<string>(pipe(
                tap(() => patchState(store, { ...setPropLoading('checkAccountStatus') })),
                switchMap((email) => authService.checkAccountStatus(email).pipe(
                    tapResponse({
                        next: (accountStatus) => {
                            patchState(store, { AccountStatus: accountStatus, ...setPropLoaded('checkAccountStatus') });
                        },
                        error: (error: HttpErrorResponse) => {
                            patchState(store, { ...setPropError('checkAccountStatus', error) });
                            errorHandlerService.handleError(error);
                        }
                    })
                ))
            )),

            sendEmailForgotPassword: rxMethod<Pick<IAuthPayload, 'Email'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('sendEmailForgotPassword') })),
                switchMap((payload) => authService.sendEmailResetPassword(payload).pipe(
                    tapResponse({
                        next: () => {
                            alertStore.openAlert({
                                title: 'Código enviado por email!',
                                message: 'Verifique o código para redefinir sua senha em seu email.',
                                mode: 'alert',
                                type: 'success',
                            });
                            patchState(store, { ...setPropLoaded('sendEmailForgotPassword') });
                        },
                        error: (error: HttpErrorResponse) => {
                            patchState(store, { ...setPropError('sendEmailForgotPassword', error) });
                            errorHandlerService.handleError(error);
                        }
                    })
                ))
            )),

            checkResetPasswordCode: rxMethod<Omit<IAuthPayload, 'AppSource' | 'Password'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('checkResetPasswordCode') })),
                switchMap((payload) => authService.checkResetPasswordCode(payload).pipe(
                    tapResponse({
                        next: () => {
                            const base64 = window.btoa(JSON.stringify(payload));
                            router.navigate(['common/redefine-password', base64]);
                            patchState(store, { ...setPropLoaded('checkResetPasswordCode') });
                            alertStore.openAlert({
                                title: 'Código confirmado!',
                                message: 'Redefina sua senha.',
                                mode: 'alert',
                                type: 'success',
                            });
                        },
                        error: (error: HttpErrorResponse) => {
                            patchState(store, { ...setPropError('checkResetPasswordCode', error) });
                            errorHandlerService.handleError(error);
                        }
                    })
                ))
            )),

            resetPassword: rxMethod<Omit<IAuthPayload, 'AppSource'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('resetPassword') })),
                switchMap((payload) => {
                    payload.Password = String(cryptoJs.MD5(payload.Email + payload.Password));
                    return authService.resetPassword(payload).pipe(
                        tapResponse({
                            next: () => {
                                patchState(store, { ...setPropLoaded('resetPassword') });
                                alertStore.openAlert({
                                    title: 'Sucesso!',
                                    message: 'Senha alterada com sucesso.',
                                    mode: 'alert',
                                    type: 'success',
                                });
                                router.navigate(['common/login']);
                            },
                            error: (error: HttpErrorResponse) => {
                                patchState(store, { ...setPropError('resetPassword', error) });
                                errorHandlerService.handleError(error);
                            }
                        })

                    )
                })
            )),

            initState,

            confirmEmailAccountAndCreatePassword: rxMethod<Omit<IAuthPayload, 'AppSource'>>(pipe(
                tap(() => patchState(store, { ...setPropLoading('confirmEmailAccountAndCreatePassword') })),
                switchMap((payload) => authService.confirmEmailAccountAndCreatePassword(payload).pipe(
                    tapResponse({
                        next: () => {
                            patchState(store, { ...setPropLoaded('confirmEmailAccountAndCreatePassword') });
                            alertStore.openAlert({
                                title: 'Sucesso!',
                                message: 'Senha criada com sucesso.',
                                mode: 'alert',
                                type: 'success',
                            });
                            router.navigate(['common/login']);
                        },
                        error: (error: HttpErrorResponse) => {
                            patchState(store, { ...setPropError('confirmEmailAccountAndCreatePassword', error) });
                            errorHandlerService.handleError(error);
                        }
                    })
                ))
            )),


            refreshToken: rxMethod<string>(pipe(
                tap(() => patchState(store, { ...setPropLoading('refreshToken') })),
                switchMap((TokenId) => authService.refreshToken(TokenId).pipe(
                    tapResponse({
                        next: (result) => {
                            setAuthData(result);
                            patchState(store, { ...setPropLoaded('refreshToken') });
                        },
                        error: (error: HttpErrorResponse) => {
                            errorHandlerService.handleError(error);
                            patchState(store, { ...setPropError('refreshToken') });
                            logout();
                        }
                    })
                ))
            ))
        }
    }),
    withReqState('AuthData', 'sendEmailForgotPassword', 'checkResetPasswordCode', 'resetPassword', 'checkAccountStatus', 'confirmEmailAccountAndCreatePassword', 'refreshToken'),
    withMethods((store, localStorageService = inject(LocalStorageService)) => {
        const getAuthData = (): IAuthAccessToken | null => {
            if (!store.AuthData()) {
                const authData = localStorageService.getItem('authorization');
                if (!authData) {
                    store.logout();
                    return null;
                }
                patchState(store, { AuthData: JSON.parse(authData) });
            }

            return store.AuthData();
        }
        return {
            getAuthData,
            isMaster(): boolean {
                const authData = getAuthData();
                return authData?.PersonId === authData?.TenantId;
            },
            isLoggedIn(): boolean {
                const isLoggedIn = !!localStorageService.getItem('authorization');
                if (!isLoggedIn) localStorageService.clearAllData();
                return isLoggedIn;
            }
        }
    })
);