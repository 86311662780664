import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { IAlertItem } from '@app-models/entities/alerts/alerts.interface';
import { v4 as uuidv4 } from 'uuid';
import { EAlertModes, EAlertTypes } from "@shared/components/alert/data-access/entities/alert.enum";
import { ApplicationRef, ComponentRef, computed, createComponent, effect, inject, untracked } from '@angular/core';
import { AlertListComponent } from '../feature/alert-list.component';

interface IAlertState {
    AlertsById: Record<string, Partial<IAlertItem>>;
    ComponentRef: ComponentRef<AlertListComponent> | null;
};

const initialState: IAlertState = {
    AlertsById: {},
    ComponentRef: null
};

export const AlertStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        appRef = inject(ApplicationRef)
    ) => {

        return {
            openAlert: (alert: Partial<IAlertItem>) => {
                if (!alert.id) alert.id = uuidv4();
                if (!alert.mode) alert.mode = EAlertModes.ALERT;
                if (!alert.type) alert.type = EAlertTypes.INFO;

                patchState(store, { AlertsById: { ...store.AlertsById(), [alert.id]: alert } });
            },

            closeAlert: (id: string) => {
                const alerts = store.AlertsById();
                delete alerts[id];
                patchState(store, { AlertsById: { ...alerts } });
            },

            createComponentRef: (): void => {
                patchState(store, { ComponentRef: createComponent(AlertListComponent, { environmentInjector: appRef.injector }) });

                if (store.ComponentRef()) {
                    document.body.appendChild(store.ComponentRef()!.location.nativeElement);
                    appRef.attachView(store.ComponentRef()!.hostView);
                }
            }

        };
    }),
    withComputed(({ AlertsById }) => ({
        Alerts: computed(() => Object.values(AlertsById())),
    })),
    withHooks({
        onInit(store) {
            effect(() => {
                const alertsLength = store.Alerts().length;

                if (alertsLength > 0 && !store.ComponentRef()) {
                    untracked(() => store.createComponentRef());
                    return;
                }

                if (alertsLength === 0) {
                    untracked(() => {
                        store.ComponentRef()?.destroy();
                        patchState(store, { ComponentRef: null });
                    });
                    return;
                }
            })
        }
    })
);