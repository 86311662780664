import { Component, inject } from "@angular/core";
import { AlertComponent } from '@shared/components/alert/feature/alert/alert.component';
import { AlertStore } from "@shared/components/alert/data-access/alert.store";

@Component({
    selector: 'app-alert-list',
    templateUrl: './alert-list.component.html',
    imports: [AlertComponent],
    standalone: true
})
export class AlertListComponent {
    public readonly alertStore = inject(AlertStore);
}