import { effect, inject, signal, untracked } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  Observable,
  switchMap,
  throwError,
} from 'rxjs';
import { filter } from 'rxjs/operators';
import { IAuthAccessToken } from '@app-auth/data-access/entities/auth.interface';
import { AuthStore } from '@app-auth/data-access/auth.store';

export class TokenInterceptor implements HttpInterceptor {
  private authStore = inject(AuthStore);
  private refreshSubject = new BehaviorSubject<IAuthAccessToken | null>(null);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthToken(request)).pipe(
      catchError((error, retryRequest) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next, retryRequest);
        }
        return throwError(() => error);
      })
    );
  }

  private addAuthToken(request: HttpRequest<any>, token?: IAuthAccessToken | null) {
    const language = signal('pt-BR')();
    let headers = new HttpHeaders().set('Accept-Language', language);

    if (!request.withCredentials) {
      return request.clone({
        headers,
      });
    }

    const _token = token ?? this.authStore.getAuthData();
    if (_token?.Token) {
      headers = headers.set('Authorization', `Bearer ${_token?.Token}`);
      return request.clone({
        withCredentials: false,
        headers,
      });
    }
    return request;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, retryRequest: Observable<any>) {
    if (!this.authStore.refreshTokenLoading()) {
      this.refreshSubject.next(null);

      if (this.authStore.isLoggedIn()) {
        this.authStore.refreshToken(this.authStore.getAuthData()?.TokenId!);
      }
    }

    return this.refreshSubject.pipe(
      filter((token) => token !== null),
      switchMap((token) => {
        return next.handle(this.addAuthToken(request, token));
      })
    );
  }

  private observeRefreshToken = effect(() => {
    if(this.authStore.refreshTokenLoaded()) {
      untracked(() => this.refreshSubject.next(this.authStore.getAuthData()));
    }
    if(this.authStore.refreshTokenError()) {
      this.refreshSubject.next(null);
    }
  });
}
