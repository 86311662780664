import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EntityPath, Protocol, url } from '@environments/environment';
import { Observable } from 'rxjs';
import { IExercise, IStatusWorkoutPlan, IUpdateLoad, IWorkoutItem, IWorkoutPlan, IWorkoutWeekLog } from '@app-training/data-access/entities/workouts.interface';

@Injectable({ providedIn: 'root' })
export class WorkoutService {
  private http = inject(HttpClient);

  public getWorkoutPlanActiveByPersonId(id: string): Observable<IWorkoutPlan> {
    return this.http.get<IWorkoutPlan>(url(Protocol.https, EntityPath.workout_view, '/Workout/GetWorkoutPlanActiveByPersonId'), {
      params: { id },
      withCredentials: true
    });
  }

  public getExerciseById(id: string): Observable<IExercise> {
    return this.http.get<IExercise>(url(Protocol.https, EntityPath.workout_service, '/Exercise'), {
      params: { id },
      withCredentials: true
    });
  }

  public updateLoad(payload: IUpdateLoad[]): Observable<boolean> {
    return this.http.post<boolean>(url(Protocol.https, EntityPath.workout_view, '/Workout/UpdateLoad'), payload, {
      withCredentials: true
    });
  }

  public finishSeries(trainingItemId: string): Observable<IWorkoutItem> {
    return this.http.post<IWorkoutItem>(url(Protocol.https, EntityPath.workout_view, '/Workout/FinishSeries'), null, {
      params: { trainingItemId },
      withCredentials: true
    });
  }

  public restartSeries(trainingItemId: string): Observable<boolean> {
    return this.http.post<boolean>(url(Protocol.https, EntityPath.workout_view, '/Workout/RestartSeries'), null, {
      params: { trainingItemId },
      withCredentials: true
    });
  }

  public getWorkoutPlanStatusActiveByPersonId(id: string): Observable<IStatusWorkoutPlan> {
    return this.http.get<IStatusWorkoutPlan>(url(Protocol.https, EntityPath.workout_view, '/Workout/GetWorkoutPlanStatusActiveByPersonId'), {
      params: { id },
      withCredentials: true
    });
  }

  public incrementSeries(trainingItemId: string): Observable<IWorkoutItem> {
    return this.http.post<IWorkoutItem>(url(Protocol.https, EntityPath.workout_view, '/Workout/IncrementSeries'), null, {
      params: { trainingItemId },
      withCredentials: true
    });
  }

  public decrementSeries(trainingItemId: string): Observable<IWorkoutItem> {
    return this.http.post<IWorkoutItem>(url(Protocol.https, EntityPath.workout_view, '/Workout/DecrementSeries'), null, {
      params: { trainingItemId },
      withCredentials: true
    });
  }

  public finishWorkout(trainingId: string): Observable<Partial<IWorkoutPlan>> {
    return this.http.post<Partial<IWorkoutPlan>>(url(Protocol.https, EntityPath.workout_view, '/Workout/FinishTraining'), null, {
      params: { trainingId },
      withCredentials: true
    });
  }

  public startWorkout(trainingId: string): Observable<boolean> {
    return this.http.post<boolean>(url(Protocol.https, EntityPath.workout_view, '/Workout/StartTraining'), null, {
      params: { trainingId },
      withCredentials: true
    });
  }

  public getWeekLogByPersonId(personId: string): Observable<IWorkoutWeekLog[]> {
    return this.http.get<IWorkoutWeekLog[]>(url(Protocol.https, EntityPath.workout_view, '/WorkoutLog/GetWeekLog'), {
      params: { personId },
      withCredentials: true
    });
  }
} 
