import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EntityPath, Protocol, url } from '@environments/environment';
import { IAccountStatus, IAuthAccessToken, IPersonByUser, IAuthPayload, IClientData } from '@app-auth/data-access/entities/auth.interface';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private http = inject(HttpClient);

    public login(payload: Omit<IAuthPayload, 'Code'>): Observable<IAuthAccessToken> {
        return this.http.post<any>(url(Protocol.https, EntityPath.user_service, '/Auth/Login'), payload);
    }

    public getAllPersonsByUser(): Observable<IPersonByUser[]> {
        const origins = 'actuar,treino';
        return this.http.get<IPersonByUser[]>(
            url(Protocol.https, EntityPath.user_service, '/UserProfile/GetAllPersonsByUser'),
            {
                params: { origins },
                withCredentials: true
            }
        );
    }

    public choosePersonAccount(personId: string): Observable<IAuthAccessToken> {
        return this.http.post<IAuthAccessToken>(
            url(Protocol.https, EntityPath.user_service, '/Auth/ChangePerson'), {},
            {
                params: { personId },
                withCredentials: true
            }
        );
    }

    public refreshToken(TokenId: string): Observable<IAuthAccessToken> {
        return this.http.post<IAuthAccessToken>(url(Protocol.https, EntityPath.user_service, '/Auth/Refresh'),
            {
                TokenId,
                Origin: 'actuar,treino',
            },
            {
                withCredentials: true,
            }
        );
    }

    public getClient(id: string): Observable<IClientData> {
        return this.http.get<IClientData>(url(Protocol.https, EntityPath.base, '/Pessoas/ObterPorId'), {
            params: {
                id
            },
            withCredentials: true,
        });
    }

    public checkAccountStatus(email: string): Observable<IAccountStatus> {
        return this.http.get<IAccountStatus>(url(Protocol.https, EntityPath.user_service, '/Auth/CheckStatus'), {
            params: {
                email
            }
        });
    }

    public sendEmailResetPassword(payload: Pick<IAuthPayload, 'Email'>): Observable<boolean> {
        return this.http.post<boolean>(url(Protocol.https, EntityPath.user_service, '/Auth/SendEmailResetPassword'),
            payload
        );
    }

    public checkResetPasswordCode(payload: Omit<IAuthPayload, 'AppSource' | 'Password'>): Observable<boolean> {
        return this.http.post<boolean>(url(Protocol.https, EntityPath.user_service, '/Auth/CheckResetPasswordCode'),
            payload
        );
    }

    public resetPassword(payload: Omit<IAuthPayload, 'AppSource'>): Observable<boolean> {
        return this.http.post<boolean>(url(Protocol.https, EntityPath.user_service, '/Auth/ResetPassword'),
            payload
        );
    }

    public confirmEmailAccountAndCreatePassword(payload: Omit<IAuthPayload, 'AppSource'>): Observable<boolean> {
        return this.http.post<boolean>(url(Protocol.https, EntityPath.user_service, '/Auth/ConfirmEmailAccountAndCreatePassword'),
            payload
        );
    }
}
