import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthStore } from '@app-auth/data-access/auth.store';


export const CommonGuard: CanActivateFn = (route, state) => {
  const authStore = inject(AuthStore);
  const router = inject(Router);
  if (authStore.isLoggedIn()) {
    router.navigate(['/home']).then();
  }
  return !authStore.isLoggedIn();
};
