export const environment = {
  local: true,
  production: false,
};

export const enum Protocol {
  http = 'http://',
  https = 'https://',
}

export const enum EntityPath {
  odata = 'odata.dev.actuar.biz',
  base = 'actuar-api.dev.actuar.biz',
  afig = 'afig-api.dev.actuar.biz',
  paguela = 'paguelacore-api.dev.actuar.biz',
  chat = 'chat-api.dev.actuar.biz',
  file_group = 'filegroup-api.dev.actuar.biz',
  workout_service = 'workoutservice-api.dev.actuar.biz',
  workout_view = 'workoutview-api.dev.actuar.biz',
  activity_service = 'activityservice-api.dev.actuar.biz',
  user_service = 'userservice-api.dev.actuar.biz',
  shortener = 'link.pague.la',
  post_service = 'postservice-api.dev.actuar.biz',
  account_service = 'accountservice-api.dev.actuar.biz',
  receivable_service = 'receivableservice-api.dev.actuar.biz',
  client_notification = 'clientnotification-api.dev.actuar.biz',
  acesso = 'acesso-api.dev.actuar.biz',
  graduation_service = 'graduationservice-api.dev.actuar.biz',
}

export const braspag = {
  providerMerchantId: 'c7f999ad-0ea4-4621-9fd6-6b3d475c5d70',
  providerOrgId: '1snn5n9w'
};

export const url = (
  protocol: Protocol,
  entityPath: EntityPath,
  restUrl?: any
) => `${protocol}${entityPath}${restUrl}`;

export const successCodesCheckout: string[] = ['4', '6', '0', '00', '000', '0000', '174'];