import {
  SignalStoreFeature,
  signalStoreFeature,
  withComputed,
  withState
} from '@ngrx/signals';
import { computed, Signal } from '@angular/core';
import { SignalStoreFeatureResult } from '@ngrx/signals/src/signal-store-models';

export type ReqState = 'init' | 'loading' | 'loaded' | { error: any };

export type NamedReqState = { [K in string as `${K}SWTState`]: ReqState; };

type LoadingKeys<Prop extends string> = `${Prop}Loading`;
type LoadedKeys<Prop extends string> = `${Prop}Loaded`;
type ErrorKeys<Prop extends string> = `${Prop}Error`;

export type NamedReqStateSignals<Prop extends string> =
  Record<LoadingKeys<Prop> | LoadedKeys<Prop> | ErrorKeys<Prop>, Signal<boolean | any | null>>

const keys = (prop: string) =>  {
  return {
    stateKey: `${prop}SWTState`,
    loadingKey: `${prop}Loading`,
    loadedKey: `${prop}Loaded`,
    errorKey: `${prop}Error`
  }
}
export function withReqState<Prop extends string>(...props: Array<Prop>): SignalStoreFeature<
  SignalStoreFeatureResult,
  { state: NamedReqState ; computed: NamedReqStateSignals<Prop>; methods: NonNullable<unknown> }>;

export function withReqState<Prop extends string>(...props: Array<Prop>): SignalStoreFeature {
  const state: {[index: string]: string} = {};

  if (props.length > 0) {
    props.forEach((property) => {
      state[keys(property).stateKey] = 'init'
    });
  }

  return signalStoreFeature(
    { state: state },
    withState( {...state} ),
    withComputed((state: any) => {

      const result: {[p: string]: Signal<any>} = {}

      if (props.length > 0) {
        props.forEach((property) => {
          const _state = state[keys(property).stateKey] as Signal<ReqState>;

          result[keys(property).loadingKey] = computed<boolean>(() => _state() === 'loading');
          result[keys(property).loadedKey] = computed<boolean>(() => _state() === 'loaded');
          result[keys(property).errorKey] = computed(() => {
            const v = _state();
            return typeof v === 'object' ? v.error : null;
          });
        });
      }
      return result;
    }),
  ) as  SignalStoreFeature;
}

export function setPropLoaded<Prop extends string>(prop: Prop): NamedReqState {
  return { [keys(prop).stateKey]: 'loaded' } as NamedReqState
}

export function setPropLoading<Prop extends string>(prop: Prop): NamedReqState {
  return { [keys(prop).stateKey]: 'loading' } as NamedReqState
}

export function setPropError<Prop extends string>(prop: Prop, error?: any): NamedReqState {
  if (error?.error?.ValidationResult) {
    error = error.error.ValidationResult;
  }

  if (error?.error?.Errors?.length) {
    error = error.error.Errors;
  }
  if (error?.error?.errors) {
    error = error.error.errors;
  }
  return { [keys(prop).stateKey]: { error } } as NamedReqState
}

export function setPropInit<Prop extends string>(...props: Array<Prop>): NamedReqState {
  const state: {[index: string]: string} = {};

  if (props.length > 0) {
    props.forEach((property) => {
      state[keys(property).stateKey] = 'init'
    });
  }

  return state as NamedReqState
}

