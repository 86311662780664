import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { IAlertItem } from '@app-models/entities/alerts/alerts.interface';
import { v4 as uuidv4 } from 'uuid';
import { EAlertModes, EAlertTypes } from "@shared/components/alert/data-access/entities/alert.enum";
import { computed } from '@angular/core';

interface IAlertState {
    AlertsById: Record<string, Partial<IAlertItem>>;
};

const initialState: IAlertState = {
    AlertsById: {},
};

export const AlertStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
    ) => {
        return {
            openAlert: (alert: Partial<IAlertItem>) => {
                if (!alert.id) alert.id = uuidv4();
                if (!alert.mode) alert.mode = EAlertModes.ALERT;
                if (!alert.type) alert.type = EAlertTypes.INFO;

                patchState(store, { AlertsById: { ...store.AlertsById(), [alert.id]: alert } });
            },

            closeAlert: (id: string) => {
                const alerts = store.AlertsById();
                delete alerts[id];
                patchState(store, { AlertsById: { ...alerts } });
            }
        };
    }),
    withComputed(({ AlertsById }) => ({
        Alerts: computed(() => Object.values(AlertsById())),
    }))
);