import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private readonly actuarPrefixKey = 'treino/';
  public setItem(key: string, value: string): void {
    const _key: string = this.actuarPrefixKey + key;
    return localStorage.setItem(_key, value);
  }

  public getItem(key: string): string | null {
    const _key: string = this.actuarPrefixKey + key;
    return localStorage.getItem(_key);
  }

  public removeItem(key: string): void {
    const _key: string = this.actuarPrefixKey + key;
    return localStorage.removeItem(_key);
  }

  public clearAllData(): void {
    return localStorage.clear();
  }
}
